import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Spinner from "react-spinkit";
import Button from "components/Elements/Button";

import MetricsRow from "components/Admin/MetricsRow";

import Label from "components/Elements/Label";
import { SmallPill } from "components/Post/styles";
import {
  SpinnerContainer,
  DashboardContainer,
  Buttons
} from "components/Admin/styles";
import {
  getUserCount,
  getTotalEnlightsRef,
  getWebProjects,
  getTutorialSubmissions,
  getPastTwoWeeksMetrics,
  getOnboardingData
} from "api/db";
import { db } from "../../api/firebase";
import { fetchActiveCohort } from "../../api/db";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [weeklyActiveUsers, setWeeklyActiveUsers] = useState(0);
  const [churnedNumber, setChurnedNumber] = useState(0);
  const [
    thisWeeksWeeklyActiveUserUids,
    setThisWeeksWeeklyActiveUserUids
  ] = useState([]);
  const [
    lastWeeksWeeklyActiveUserUids,
    setLastWeeksWeeklyActiveUserUids
  ] = useState([]);
  const [tutorialStarts, setTutorialStarts] = useState(0);
  const [tutorialCompletions, setTutorialCompletions] = useState(0);
  const [enlights, setEnlights] = useState(0);
  const [webProjects, setWebProjects] = useState(null);
  const [submittedProjects, setSubmittedProjects] = useState(null);
  const [rebuild, setRebuild] = useState(false);

  const [experienceData, setExperienceData] = useState(null);
  const [interestData, setInterestData] = useState(null);
  const [motivationData, setMotivationData] = useState(null);
  const [numOnboarded, setNumOnboarded] = useState(null);

  const [activeCohort, setActiveCohort] = useState("");

  useEffect(() => {
    fetchActiveCohort().once("value", snapshot => {
      setActiveCohort(snapshot.val());
    });

    getUserCount().then(snapshot => {
      setUserCount(snapshot.val().count);
    });

    getOnboardingData().then(snapshot => {
      let data = snapshot.val();
      setNumOnboarded(data.count);
      setExperienceData(data.experience);
      setInterestData(data.interest);
      setMotivationData(data.motivation);
    });

    getPastTwoWeeksMetrics().then(snapshot => {
      let twoWeeksData = Object.values(snapshot.val());
      let thisWeek = twoWeeksData.slice(7, 14);
      let lastWeek = twoWeeksData.slice(0, 7);

      let lastWeekUids = new Set();
      lastWeek.map(day => {
        Object.keys(day.uids).map(uid => {
          lastWeekUids.add(uid);
        });
      });

      let thisWeekUids = new Set();
      thisWeek.map(day => {
        Object.keys(day.uids).map(uid => {
          thisWeekUids.add(uid);
        });
      });

      let thisWeekUidsArray = Array.from(thisWeekUids);
      let lastWeekUidsArray = Array.from(lastWeekUids);

      setThisWeeksWeeklyActiveUserUids(thisWeekUidsArray);
      setLastWeeksWeeklyActiveUserUids(lastWeekUidsArray);

      let churnedUsers = [];
      lastWeekUidsArray.map(uid => {
        if (!thisWeekUidsArray.includes(uid)) {
          churnedUsers.push(uid);
        }
      });
      setChurnedNumber(churnedUsers.length);

      let tutorialStarts = 0;
      let tutorialCompletions = 0;
      let activeUsers = new Set();
      thisWeek.forEach(childSnapshot => {
        let data = childSnapshot;
        tutorialStarts += data.tutorialStarts;
        tutorialCompletions += data.tutorialCompletions;
        let uids = Object.keys(data.uids);
        uids.forEach(uid => {
          activeUsers.add(uid);
        });
      });
      setTutorialStarts(tutorialStarts);
      setTutorialCompletions(tutorialCompletions);
      setWeeklyActiveUsers(activeUsers.size);
    });

    getTotalEnlightsRef().then(snapshot => {
      let totalEnlights = 0;
      snapshot.forEach(childSnapshot => {
        totalEnlights += childSnapshot.val().enlights;
      });
      setEnlights(totalEnlights);
    });

    getWebProjects().then(snapshot => {
      let data = Object.entries(snapshot.val());
      data.sort((a, b) => (a[1].projectId > b[1].projectId ? -1 : 1));
      setWebProjects(data);
    });

    getTutorialSubmissions().then(snapshot => {
      setSubmittedProjects(snapshot.val());
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>Metrics</Label>
      <br />
      {submittedProjects ? (
        <>
          <MetricsRow
            rowData={[
              [weeklyActiveUsers, "Weekly Active Users"],
              [tutorialStarts, "Tutorial Starts, Weekly"],
              [tutorialCompletions, "Tutorial Completions, Weekly"],
              [
                (tutorialCompletions / tutorialStarts) * 100,
                "Completion Rate, Weekly"
              ]
            ]}
          />
          <MetricsRow
            rowData={[
              [churnedNumber, "Churned Weekly"],
              [enlights, "Total Enlights"],
              [userCount, "Total Users"],
              [Object.entries(submittedProjects).length, "Tutorial Submissions"]
            ]}
          />
          <br />
        </>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}
      <Buttons>
        <Link to="/admin/active">Active Users</Link>
        <Link to="/admin/churned">Churned Users</Link>
        <Link to="/admin/completions">Project Completions</Link>
        <Link to="/admin/marketing">Marketing Copy</Link>
        <Link to="/admin/onboarding">Onboarding</Link>
        <Link to={activeCohort ? `/admin/cohort/${activeCohort}` : "/"}>
          Cohort
        </Link>
      </Buttons>
      <br />
      <Label> Live Posts </Label>
      <br />
      {webProjects ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Author</th>
              <th>Date</th>
              <th>Published</th>
              <th>Category</th>
              <th>Language</th>
              <th>Difficulty</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {webProjects.map((project, index) => {
              return (
                <tr key={index}>
                  <td>{project[1].projectId}</td>
                  <td>
                    <a href={`/projects/${project[0]}`}>{project[1].title}</a>
                  </td>
                  <td>
                    <a href={`/user/${project[1].author_username}`}>
                      @{project[1].author_username}
                    </a>
                  </td>
                  <td>{project[1].date}</td>
                  <td>
                    {project[1].published ? (
                      <SmallPill c="#7ef29d" small>
                        True
                      </SmallPill>
                    ) : (
                      <SmallPill c="#f7b3b7" small>
                        False
                      </SmallPill>
                    )}
                  </td>
                  <td>
                    <SmallPill c="#D1F0FD" small>
                      {project[1].category}
                    </SmallPill>
                  </td>
                  <td>
                    <SmallPill c="#FFEBB6" small>
                      {project[1].language}
                    </SmallPill>
                  </td>
                  <td>
                    <SmallPill c="#f7b3b7" small>
                      {project[1].difficulty}
                    </SmallPill>
                  </td>
                  <td>
                    <a href={`/edit/${project[0]}`}>
                      <SmallPill c="#f2a2dc" small>
                        Edit
                      </SmallPill>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}
      <br />
      <Label> Submitted Posts </Label>
      <br />
      {submittedProjects ? (
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Date</th>
              <th>Published</th>
              <th>Submitted</th>
              <th>Category</th>
              <th>Language</th>
              <th>Difficulty</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(submittedProjects).map((project, index) => {
              return (
                <tr key={index}>
                  <td>
                    <a href={`/drafts/${project[1].id}`}>{project[1].title}</a>
                  </td>
                  <td>
                    <a href={`/user/${project[1].author_username}`}>
                      @{project[1].author_username}
                    </a>
                  </td>
                  <td>{project[1].date}</td>
                  <td>
                    {project[1].published ? (
                      <SmallPill c="#7ef29d" small>
                        True
                      </SmallPill>
                    ) : (
                      <SmallPill c="#f7b3b7" small>
                        False
                      </SmallPill>
                    )}
                  </td>
                  <td>
                    {project[1].submitted ? (
                      <SmallPill c="#7ef29d" small>
                        True
                      </SmallPill>
                    ) : (
                      <SmallPill c="#f7b3b7" small>
                        False
                      </SmallPill>
                    )}
                  </td>
                  <td>
                    <SmallPill c="#D1F0FD">{project[1].category}</SmallPill>
                  </td>
                  <td>
                    <SmallPill c="#FFEBB6">{project[1].language}</SmallPill>
                  </td>
                  <td>
                    <SmallPill c="#f7b3b7">{project[1].difficulty}</SmallPill>
                  </td>
                  <td>
                    <a href={`/drafts/${project[1].id}`}>
                      <SmallPill c="#f2a2dc" small>
                        Edit
                      </SmallPill>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      )}
      <br />
      <br />
      {rebuild ? (
        <Button> Done &#10004; </Button>
      ) : (
        <Button
          onClick={() => {
            fetch(
              "https://api.netlify.com/build_hooks/5ec6d6778203aa4bdb62f224",
              {
                method: "post"
              }
            );
            setRebuild(true);
          }}
        >
          Rebuild Site and Publish Changes
        </Button>
      )}
      &nbsp;&nbsp;
      <Button
        onClick={() => {
          const metrics = {
            weeklyActiveUsers: weeklyActiveUsers,
            weeklyTutorialStarts: tutorialStarts,
            tutorialCompletions: tutorialCompletions,
            completionRate: tutorialCompletions / tutorialStarts,
            totalEnlights: enlights,
            totalUsers: userCount,
            submissions: Object.entries(submittedProjects).length,
            numberOnboarded: numOnboarded,
            webDevInterest: interestData["web-development"],
            mlInterest: interestData["machine-learning"],
            bothInterest:
              interestData["web-development"] +
              interestData["machine-learning"] -
              numOnboarded,
            percentageWebDev: interestData["web-development"] / numOnboarded,
            percentageML: interestData["machine-learning"] / numOnboarded,
            percentageBoth:
              (interestData["web-development"] +
                interestData["machine-learning"] -
                numOnboarded) /
              numOnboarded,
            jobSeekers: motivationData["career"],
            explorers: motivationData["exploration"],
            otherMotivation: motivationData["other"],
            percentageBeginner: experienceData["beginner"] / numOnboarded,
            percentageSemi: experienceData["semi-beginner"] / numOnboarded,
            percentageIntermediate:
              experienceData["intermediate"] / numOnboarded,
            percentageAdvanced: experienceData["advanced"] / numOnboarded,
            weeklyChurn: churnedNumber
          };
          var data = new FormData();
          for (var key in metrics) {
            data.append(key, metrics[key]);
          }
          fetch(
            "https://script.google.com/macros/s/AKfycbwc8FexOWyINwaXHMwTX50_2i6Sb4SeVwqO1g2gWPyNfphDh6o/exec",
            { method: "POST", body: data }
          )
            .then(response => console.log("Success!", response))
            .catch(error => console.error("Error!", error.message));
        }}
      >
        Update Metrics in Google Sheet
      </Button>
    </DashboardContainer>
  );
};

export default Dashboard;
